import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import { bgService } from "../images"
import { BackgroundImages } from "../shared"
import { OutboundLink } from 'gatsby-plugin-gtag'

const NotFoundPage = () => (
  <Layout pageInfo={{ pageName: "404 Not Found" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>404</title>
        <meta name="description" content="404" />
    </Helmet>
    <div className="header-not-found">
      <BackgroundImages style={{
        backgroundImage: `url(${bgService})`,
      }}>
        <OutboundLink href="/contact-us"className="text-services">Contact us</OutboundLink>
      </BackgroundImages>
    </div>
    <div className="content-not-found">
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
